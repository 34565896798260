<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      :headers="headers"
      :items="subscriptions"
      :page.sync="page"
      :items-per-page.sync="pageSize"
      item-key="id"
      loading-text="Loading Subscriptions...Please Wait"
      class="elevation-1"
      dense
      hide-default-footer
    >
      <template v-slot:[`item.updated_on`]="{ item }">
        {{ formatDate(item.updated_on) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="grey lighten-3">
          <v-toolbar-title>Subscriptions</v-toolbar-title>
          <v-divider vertical inset class="mx-4" />
          <v-row align="center">
          <v-spacer></v-spacer>
            <v-col md="8" lg="6" xl="6">
              <PartySearch :partyChange="partySelected" />
            </v-col>
            <v-col md="4" lg="3" xl="3">
              <v-select
                class=""
                outlined
                dense
                v-model="selectedType"
                :items="types"
                item-text="name"
                item-value="id"
                label="Subscription Types"
                clearable
                background-color="#fff"
                hide-details
                color='primary'
              />
            </v-col>
          </v-row>

          <!-- Delete Dialog -->
          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteSubscriptionConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editSubscription(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteSubscription(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer>
        <v-row align="center">
          <v-col cols="7"></v-col>
          <v-col cols="1">
          <v-btn small :disabled="disablePrev" @click="firstPage">
            <v-icon>mdi-arrow-collapse-left</v-icon>
          </v-btn>
          </v-col>
          <v-col cols="1">
          <v-btn small :disabled="disablePrev" @click="prevPage">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          </v-col>
          <v-col cols="1">
          <v-btn small :disabled="disableNext" @click="nextPage">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          </v-col>
          <v-col cols="2">
          <v-select
            :items="pageSizes"
            :value="pageSize"
            label="Page Size"
            @change="updatePageSize"
          ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <SubscriptionEdit
      :types="types"
      :display="subscriptionModal"
      :partyId="selectedPartyId"
      v-on:subscription-edit-close="closeSubscriptionEdit"
    />

  </v-container>
</template>

<script>
// API
import Subscription from "@/axios/subscription-endpoint";
import kv from "@/axios/key-values-endpoint";

// Third-Party Libraries
import { format } from "date-fns";

// Components
import SubscriptionEdit from "@/components/modals/subscription_edit"
import PartySearch from "@/components/parties/party_search";

// Mixins
import { displayAlert } from '@/mixins/alert'
export default {
  name: "subscription",
  mixins: [displayAlert],
  data: () => ({
    loading: true,
    page: 1,
    pageSize: 25,
    pageSizes: [25, 50, 100],
    nonTableHeight: 190,
    tableHeight: "",
    windowHeight: "",
    search: "",
    typeSearch: "",
    event: "",
    notification: false,
    subscriptionModal: false,
    subscription: {},
    subscriptions: [],
    types: [],
    partyId: null,
    subscriptionId: "",
    selectedType: null,
    deleteDialog: false,
    selectedPartyId: ""
  }),

  components: {
    PartySearch,
    SubscriptionEdit,
  },

  watch: {
    windowHeight: {
      handler() {
        this.tableHeight = this.windowHeight - this.nonTableHeight;
      },
    },

    nonTableHeight: {
      deep: "true",
      handler(newValue) {
        this.tableHeight = this.windowHeight - newValue;
      },
    },

    selectedType: {
      handler() {
        this.initSubscriptions();
      },
    },

    deleteDialog (val) {
        val || this.closeDeleteDialog()
      },

  },

  computed: {
    headers() {
      return [
        { 
          sortable: false, 
          class: 'accent' 
        },
        {
          text: "Party",
          filterable: false,
          sortable: false,
          value: "party.name",
          class: 'accent white--text'
        },
        {
          text: "Type",
          filterable: false,
          sortable: false,
          value: "party.party_type.name",
          class: 'accent white--text'
        },
        {
          text: "Subscription Type",
          filterable: false,
          sortable: false,
          value: "subscription_type.name",
          class: 'accent white--text'
        },
        {
          text: "Updated On",
          filterable: false,
          sortable: false,
          value: "updated_on",
          class: 'accent white--text'
        },
        {
          text: "Updated By",
          filterable: false,
          sortable: false,
          value: "updated_by",
          class: 'accent white--text'
        },
        {
          text: "Actions",
          filterable: false,
          sortable: false,
          value: 'actions',
          class: 'accent white--text'
        }
      ];
    },

    disableNext() {
      var disable = true
      if (this.subscriptions.length === this.pageSize) {
        disable = false
      }
      return disable
    },

    disablePrev() {
      var disable = false
      if (this.page === 1) {
        disable = true
      }
      return disable
    },
  },

  created() {
    this.tableHeight = this.windowHeight - this.nonTableHeight;
    window.addEventListener("resize", this.tableResize);
    this.tableResize();

    this.initSubscriptions();
    this.initTypes();
  },

  methods: {
    filterByType() {
      return this.typeSearch.id;
    },

    close() {
      this.subscriptionModal = false;
      this.subscription = {};
      this.initSubscriptions();
    },

    notify(event) {
      this.initSubscriptions();
      this.event = event;
      this.subscriptionModal = false;
      this.notification = true;
      setTimeout(() => {
        this.notification = false;
      }, 3000);
    },

    formatDate(date) {
      return format(Date.parse(date), "M/d/yyyy h:mm a");
    },

    async initSubscriptions() {
      const offset = (this.page - 1) * this.pageSize;
      const pageSize = this.pageSize;
      this.loading = true
      try {
      this.subscriptions = (await Subscription.getList(
        pageSize,
        offset,
        this.partyId,
        this.selectedType
      )).data
      } catch (err) {
        this.handleError(err)
      }
      this.loading = false;
    },
    async initTypes() {
      try {
        this.types = (await kv.keyValues.getList("SUBSCRIPTION_TYPE", 100, 0)).data
      } catch (err) {
        this.handleError(err)
      }
    },

    tableResize() {
      this.windowHeight = window.innerHeight;
    },

    open(subscription) {
      if (subscription) {
        this.subscription = subscription;
      } else {
        this.subscription = {};
      }
    },

    updatePage(newPageNumber) {
      this.page = newPageNumber;
      this.initSubscriptions();
    },

    updatePageSize(newPageSize) {
      this.page = 1;
      this.pageSize = newPageSize;
      this.initSubscriptions();
    },

    partySelected(partyId) {
      this.partyId = partyId;
      this.initSubscriptions();
    },

    firstPage(){
      this.page = 1
      this.initSubscriptions()
    },

    prevPage(){
      this.page = this.page - 1
      this.initSubscriptions()
    },

    nextPage(){
      this.page = this.page + 1
      this.initSubscriptions()
    },

    deleteSubscription(subscription){
      this.subscriptionId = subscription.id
      this.deleteDialog = true
    },

    async deleteSubscriptionConfirm () {
      try {
        await Subscription.delete(this.subscriptionId)
        this.initSubscriptions()
        this.closeDeleteDialog()
      } catch (err) {
        this.handleError(err)
      }
    },

    closeDeleteDialog(){
      this.deleteDialog = false
    },

    closeSubscriptionEdit(){
      this.subscriptionModal = false
      this.selectedPartyId = null
    },

    editSubscription(subscription){
      this.selectedPartyId = subscription.party_id
      this.subscription = subscription
      this.subscriptionModal = true
    }
  },
};
</script>
