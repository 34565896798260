<template>
  <v-dialog
    @click:outside="closeDialog()"
    v-model="displayDialog"
    max-width="750px"
  >
    <v-card>
      <v-toolbar color="#37474F" dark flat>
        <v-toolbar-title>{{ this.dialogTitle }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-actions>
        <v-container>
          <v-row align="center">
            <v-col cols="6">
              <PartySearch :partyId="selectedPartyId" />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedType"
                :items="types"
                item-text="name"
                item-value="id"
                label="Subscription Types"
                multiple
                chips
                dense
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6"><v-spacer /></v-col>
            <v-col cols="3">
              <v-btn color="blue darken-1" text @click="closeDialog"
                >Cancel</v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-btn color="blue darken-1" text @click="saveSubscriptions"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Subscription from "@/axios/subscription-endpoint"
import PartySearch from "@/components/parties/party_search"

// mixins
import { displayAlert } from "@/mixins/alert"

export default {
  name: "subscription-edit",
  props: {
    types: {
      type: Array,
      required: true,
    },
    display: {
      type: Boolean,
      required: true,
    },
    partyId: {
      type: String,
    },
  },

  components: {
    PartySearch,
  },

  created() {
    this.selectedPartyId = this.partyId
    this.getSubscriptionForParty()
    this.displayDialog = this.display
  },
  data: () => ({
    displayDialog: false,
    selectedType: null,
    dialogTitle: "",
    selectedPartyId: null,
    subscriptionTypesForParty: [],
    subscriptionsForParty: [],
    subscriptionsToDelete: [],
    test: [],
  }),
  methods: {
    async getSubscriptionForParty() {
      if (this.selectedPartyId) {
        //TODO Try/Catch to catch errors and invoke alert bar component
        const res = await Subscription.getList(200, 0, this.selectedPartyId)
        this.subscriptionsForParty = res.data
        this.loading = false
        this.subscriptionTypesForParty = this.subscriptionsForParty.map(
          (x) => x.subscription_type
        )
        /* we need to get all subscriptions so we can check it against what is passed into deleteSubscriptions()
        normally we'd use this.types, but eventually this.previousVals will be mutated. */
        this.previousVals = res.data
        this.selectedType = this.subscriptionTypesForParty
      } else {
        this.subscriptionsForParty = []
        this.subscriptionTypesForParty = []
      }
    },

    async saveSubscriptions() {
      let selectedTypes = this.selectedType
      if (!Array.isArray(this.selectedType)) {
        selectedTypes = [this.selectedType.id]
      }

      // saveSubscriptions will check if a user is deleting anything, if they are, then delete their chosen subscription
      if (this.subscriptionsToDelete) {
          try{
            await Promise.allSettled(this.subscriptionsToDelete.map(e => Subscription.delete(e.id)))
          } catch (err) {
            console.error(err)
          }
      }
      const subscriptions = selectedTypes.flatMap((typeId) => {
        const found = this.subscriptionTypesForParty.find(
          (element) => element.id === typeId
        )
        if (!found) {
          // could just return the object, but using variable for clarity
          const subscription = {
            party_id: this.selectedPartyId,
            subscription_type_id: typeId,
          }
          return subscription
        }
        return []
      })
      if (subscriptions.length > 0) {
        const results = await Promise.allSettled(
          subscriptions.map((subscription) => {
            return Subscription.post(subscription)
          })
        )
        let hasError = false
        for (const idx in results) {
          const result = results[idx]
          if (result.status === "rejected") {
            hasError = true
          }
        }

        if (hasError) {
          this.handleError({
            message:
              "An error occurred during saving. Please review your data and try again.",
          })
        } else {
          this.closeDialog()
        }
      }
    },

    closeDialog() {
      this.subscriptionsToDelete = []
      this.displayDialog = false
      this.selectedType = null
      this.$emit("subscription-edit-close")
    },
  },

  mixins: [displayAlert],

  watch: {
    selectedType: function(newV) {
        // find the subscriptions a user wishes to delete and create an array of those subscriptions id's
      if (newV) this.subscriptionsToDelete = this.previousVals.filter((sub) => !newV.includes(sub.subscription_type.id))
    },
    display: function(val) {
      this.displayDialog = val
    },

    partyId: function(val) {
      this.selectedPartyId = val
      this.getSubscriptionForParty()
      if (this.selectedPartyId) {
        this.dialogTitle = "Subscription - Edit"
      } else {
        this.dialogTitle = "Subscription - Create"
      }
    },
  },
}
</script>
