var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      outlined: "",
      items: _vm.items,
      loading: _vm.isLoading,
      "search-input": _vm.search,
      filter: function() {
        return true
      },
      readonly: _vm.readOnly,
      clearable: "",
      "auto-select-first": "",
      "hide-no-data": "",
      dense: "",
      "item-text": "name",
      "item-value": "id",
      placeholder: "Search for Party",
      label: "Party",
      "prepend-inner-icon": "mdi-magnify",
      "background-color": "#fff",
      "hide-details": "",
      color: "primary"
    },
    on: {
      "update:searchInput": function($event) {
        _vm.search = $event
      },
      "update:search-input": function($event) {
        _vm.search = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-list-item-icon",
              [
                _c("v-icon", [
                  _vm._v(_vm._s(_vm._f("getIcon")(item.party_type.constant)))
                ])
              ],
              1
            ),
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", {
                  domProps: { textContent: _vm._s(item.name) }
                }),
                _c("v-list-item-subtitle", {
                  domProps: { textContent: _vm._s(item.party_type.name) }
                })
              ],
              1
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }