<template>
    <v-autocomplete
      class=""
      outlined
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      :filter="()=>true"  
      :readonly="readOnly"
      clearable
      auto-select-first
      hide-no-data
      dense
      item-text="name"
      item-value="id"
      placeholder="Search for Party"
      label="Party"
      prepend-inner-icon="mdi-magnify"
      background-color="#fff"
      hide-details
      color='primary'
      >

    <template v-slot:item="{ item }">
        <v-list-item-icon>
            <v-icon>{{ item.party_type.constant | getIcon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.party_type.name"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
</template>


<script>
import { debounce } from '@/helpers'

export default {
  props: {
    partyChange: {
      type: Function
    },
    partyId: {
      type: String
    }
  }, 

  data: () => ({
    nameLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: '',
    debounceSearch: null,
    readOnly: false,
    selectedPartyId: null
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry._source);
      });
    },
  },

  filters: {
      getIcon(partyType){
          if(partyType === "STORE"){
              return "mdi-store-outline"
          }
          else if(partyType === "DISTRIBUTION_CENTER"){
              return "mdi-truck"
          }
          else if(partyType === "WHOLESALER"){
              return "mdi-office-building-outline"
          }
          else if(partyType === "USER"){
              return "mdi-account-outline"
          }
          else if(partyType === "VENDOR"){
            return "mdi-factory"
          }
          else if(partyType === "OWNER"){
            return "mdi-account-group-outline"
          }
      }
  },

  created(){
    this.selectedPartyId = this.partyId
    this.debounceSearch = debounce(this.partySearch, 500)
    if(this.selectedPartyId){
      this.readOnly = true
    }
  },

  methods: {
    partySearch(){
    //   // Items have already been requested
    //   if (this.isLoading) return;

    //   this.isLoading = true;

    //   // Lazily load input items
    //   if(this.selectedPartyId){
    //     partySearch
    //       .searchByPartyId(this.$auth.tenant, this.selectedPartyId)
    //       .then((res) => {
    //         const count = res.hits.total;
    //         const entries = res.hits.hits;
    //         this.count = count;
    //         this.entries = entries;
    //         this.model = this.entries[0]._source;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //       .finally(() => (this.isLoading = false));
          
    //   }
    //   else {
    //     partySearch
    //       .searchParty(this.$auth.tenant, this.search)
    //       .then((res) => {
    //         const count = res.hits.total;
    //         const entries = res.hits.hits;
    //         this.count = count;
    //         this.entries = entries;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //       .finally(() => (this.isLoading = false));
    //   }
    }
  },

  watch: {
    search(search) {
      this.search = search
      this.debounceSearch(search)
    },

    partyId: function(val) {
        this.selectedPartyId = val
        this.partySearch()
    },

    model(val){
      if(typeof this.partyChange === "function"){
        this.partyChange(val)
      }
    }
  },
}
</script>