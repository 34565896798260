import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Subscription {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.subscription = base
  }

  handleSuccess (response) {
    return response
  }

  async get () {
    await AuthToken.setAuth(this.subscription)

    return this.subscription({
      url: '/v1/subscription',
      method: 'GET'
    })
  }

  async getList (limit, offset, partyId = '', subscription_type_id = '') {
    await AuthToken.setAuth(this.subscription)

    var params = {}

    if(partyId){
      params['party_id'] = partyId
    }

    if(subscription_type_id){
      params['subscription_type_id'] = subscription_type_id
    }


    const url = 'v1/subscription/' + limit + '/' + offset
    return this.subscription({
      url: url, 
      method: 'GET',
      params: params
      })
  }

  async post (subscription) {

    await AuthToken.setAuth(this.subscription)

    return this.subscription({
      url: '/v1/subscription',
      method: 'POST',
      data: subscription
    })
  }

  async put (subscription) {

    await AuthToken.setAuth(this.subscription)

    return this.subscription({
      url: '/v1/subscription',
      method: 'PUT',
      data: subscription
    })
  }

  async delete (subscription) {

    await AuthToken.setAuth(this.subscription)

    return this.subscription({
      url: `/v1/subscription/${subscription}`,
      method: 'DELETE'
    })
  }
}

export default new Subscription()
